import { FC, useEffect, useState } from 'react';
import {
	TextField,
	FormControlLabel,
	Checkbox,
	Typography,
	Button,
	Card,
	CardContent,
	CardActions,
	Backdrop,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	Divider,
	FormGroup,
	FormHelperText,
} from '@mui/material';
import { Build } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Survey as SurveyType } from './server';

const schema = yup.object({
	email: yup.string().email().required(),
	name: yup.string(),
	location: yup.string(),
	pledge: yup.number().positive().min(0).max(300),
	note: yup.string(),
});


export const Survey: FC<{
	onSubmit: (data: any) => void;
	onClose: () => void;
}> = ({ onSubmit, onClose }) => {
	const [mode, setMode] = useState({
		includePledge: false,
		includeNote: false,
	});
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
		getValues,
	} = useForm<SurveyType>({
		defaultValues: {
			email: '',
			name: '',
			location: '',
			pledge: undefined,
			note: '',
		},
		resolver: yupResolver(schema),
	});


	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={true}
			>
				<Card
					sx={{ maxWidth: 500, width: '100%', marginTop: 8, marginBottom: 8 }}
				>
					<CardContent>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginBottom: 8,
							}}
						>
							<Build style={{ marginRight: 4 }} />
							<Typography variant="h4">Survey</Typography>
							<Build style={{ marginLeft: 4 }} />
						</div>
						<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
							<Divider>Required</Divider>
							<Controller
								name="email"
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<TextField
										{...field}
										label="Email"
										required
										error={!!errors.email}
										helperText={errors.email ? errors.email.message : ''}
									/>
								)}
							/>
							<Divider>Optional</Divider>
							<Controller
								name="name"
								control={control}
								rules={{ required: false }}
								render={({ field }) => <TextField {...field} label="Name" />}
							/>
							<Controller
								name="location"
								control={control}
								rules={{ required: false }}
								render={({ field }) => (
									<TextField {...field} label="Town" />
								)}
							/>
							<FormGroup
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}
							>
								{mode.includePledge && (
									<Controller
										name="pledge"
										control={control}
										rules={{ required: false }}
										render={({ field }) => (
											<FormControl fullWidth sx={{ m: 0, width: '60%' }}>
												<InputLabel htmlFor="outlined-adornment-amount">
													Amount
												</InputLabel>
												<OutlinedInput
													{...field}
													type="number"
													id="outlined-adornment-amount"
													startAdornment={
														<InputAdornment position="start">$</InputAdornment>
													}
													error={!!errors.pledge}
													label="Amount"
												/>
												{!!errors.pledge && (
													<FormHelperText error id="accountId-error">
														Pledges between 0 & 300 only
													</FormHelperText>
												)}
											</FormControl>
										)}
									/>
								)}
								<FormControlLabel
									style={{ width: mode.includePledge ? '30%' : '100%' }}
									control={
										<Checkbox
											checked={mode.includePledge}
											onClick={() => {
												setMode({
													...mode,
													includePledge: !mode.includePledge,
												});
											}}
										/>
									}
									label="Include a pledge"
								/>
							</FormGroup>
							<Controller
								name="note"
								control={control}
								rules={{ required: false }}
								render={({ field }) => (
									<TextField
										{...field}
										id="outlined-multiline-static"
										label="Note"
										multiline
										rows={4}
										defaultValue=""
									/>
								)}
							/>
						</div>
					</CardContent>
					<CardActions sx={{ justifyContent: 'space-between' }}>
						<Button
							variant="outlined"
							onClick={() => {
								onClose();
							}}
						>
							Close
						</Button>
						<Button
							variant="contained"
							onClick={() => {
								console.log(getValues(), errors);
								handleSubmit(
									(e, b) => {
										// console.log('waaa', e, b);
										onSubmit(e);
									},
									(err) => {
										console.log('errr', err);
									}
								)();
							}}
							type="submit"
						>
							Submit
						</Button>
					</CardActions>
				</Card>
			</Backdrop>
		</form>
	);
};

export default Survey;
