import { FC, useEffect, useState } from 'react';
import {
	Alert,
	Slider,
	Button,
	Container,
	Chip,
} from '@mui/material';
import { Survey } from './server';
import SurveyComponent from './Survey';

import './style.css';

const surveyEndpoint = '/api/survey'
export const App: FC<{ name: string }> = ({ name }) => {
	const [survey, setSurveyResults] = useState({
		makers: 1,
		pledge: 200000,
	})
	const [expanded, expand] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const onSubmit = (s: Survey) => {
		const submit = async () => {
			const results = await fetch(surveyEndpoint, {
				method: 'POST',
				headers: {
				  'Accept': 'application/json',
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify(s)
			});
			setSubmitted(true);
		}
		submit();
	};
	const onClose = () => {
		expand(false);
	};

	useEffect(() => {
		const getSurveyResults = async () => {
			try {
				const results = await fetch(surveyEndpoint, {
					mode: 'cors'
				});
				const r = await results.json();
				setSurveyResults({
					...r
				} as { makers: number, pledge: number })
			} catch (err) {
				console.log('ERRR', err);
			}
		}
		getSurveyResults();
	}, [])

	return (
		<Container>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<img src="/rockmake2.png" style={{maxWidth: 600, width: '100%'}} />
				<div
					style={{
						padding: 8,
						backgroundColor: 'rgb(82, 175, 119)',
						borderRadius: 4,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Chip label={`${survey.makers}`} size="small" sx={{ marginRight: 1 }} /> Makers
				</div>
				<p>
					We're reaching out to <b>Rockland County, NY</b> (Any any nearby towns).
					To explore the idea and interest of creating a Community <b>Makerspace</b> right
					here.  <a href="#survey">Go Directly to survey</a>
				</p>
				<p>
					A makerspace is a collaborative workspace where people of all skill
					levels can come together to share tools and knowledge, work on
					projects, learn new skills, and build community. Whether you're into
					3D printing, woodworking, electronics, art, or just tinkering around
					with new ideas, a makerspace offers the resources and community
					support to bring your visions to life.
				</p>
				<p>View the video below to get an idea of what an example makerspace is</p>
				<iframe style={{ width: '100%', maxWidth: 560 }}
					width="560"
					height="315"
					src="https://www.youtube-nocookie.com/embed/qBZH4NdUpAE?si=QLHkeI0l-mXrofy6"
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share">
				</iframe>
				<p>
					Before we move forward, we want to hear from you! Are you interested
					in having a makerspace in our community? If this idea peaks your
					interest, Please take a moment and fill out our survey below. Your
					input is incredibly valuable to us, and it will help shape the future
					of this exciting project.
				</p>
				<p>
					<b style={{ color: 'rgb(232, 106, 80)' }}>Makers:</b> Anyone who fills
					out the survey is labeled a{' '}
					<b>
						<i>Maker</i>
					</b>
					, more important than the pledge amount is the amount of people in our
					community interested in this space. This number helps us determine if
					this is a worthwhile effort, and to what extent we should accomodate
					our community size wise.
				</p>
				<p>
					<b style={{ color: 'rgb(232, 106, 80)' }}>Pledges:</b> Any pledges are{' '}
					<b>
						<i>non binding</i>
					</b>{' '}
					(as such we arent asking for any card information), we will use that
					information in the case where this project has enough momentum and
					reach out via email to see if you are still interested in committing
					to the pledge. The pledge amount will help dictate how much we can
					initially invest in the space. Any pledgers will also be invited to
					participate in early community / voting boards as well as receive
					future membership benefits.
				</p>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 10,
				}}
			>
				<Slider
					style={{
						color: '#52af77',
						height: 8,
						marginTop: 12,
					}}
					aria-label="Always visible"
					value={survey.pledge}
					valueLabelFormat={(value: number) => {
						const v = Math.ceil(value / 1000);
						return `${v}k Pledged`;
					}}
					getAriaValueText={(value: number) => {
						const v = Math.ceil(value / 1000);
						return `${v}k pledged`;
					}}
					step={1000}
					min={0}
					max={300000}
					marks={[
						{
							value: 200000,
							label: '200k',
						},
						{
							value: 250000,
							label: '250k',
						},
					]}
					valueLabelDisplay="on"
				/>
			</div>
			{submitted && (
				<Alert severity="success">
					Thank you for submitting your interest in our Makerspace Community @
					Rockland.
				</Alert>
			)}
			{!submitted && (
				<div id="survey" style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
					style={{ scale: '1.5', marginTop: 16, marginBottom: 16}}
						variant="contained"
						color="success"
						size="large"
						onClick={() => {
							expand(!expanded);
						}}
					>
						{!expanded && `Fill Survey`}
						{expanded && `Hide Survey`}
					</Button>
				</div>
			)}
			{expanded && !submitted && (
				<SurveyComponent onSubmit={onSubmit} onClose={onClose} />
			)}
		</Container>
	);
};
